import React from "react";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import lucia from "./assets/lucia.jpg";
import ana from "./assets/ana.jpg";
import Button from "react-bootstrap/Button";
import monica from "./assets/monica.jpg";
import adriano from "./assets/adriano.jpg";
import miguellourenco from "./assets/miguellourenco.jpg";
import ContactForm from "./ContactForm";
import { Container, Row, Col } from "react-bootstrap";
function OurFamily() {
  return (
    <div>
      <MenuBar />
      <Container className="d-flex justify-content-center  mt-5">
        <Row
          xs={1}
          md={2}
          className="d-flex justify-content-around  align-items-center mt-5 "
        >
          <Col className="d-flex flex-column">
            <img src={monica} className="img-fluid ml-5" alt="yoga" />
            <h1>Monica</h1>
            <h5>Founder & Manager</h5>
            <p style={{ textAlign: "justify" }}>
              From Rome (Italy), she is a “forever-student” and lively Hatha
              Yoga practitioner since 2013. Passionate from travels and people,
              she is a former translator and teacher. She arrived in Ericeira
              with the dream of creating a cosy home stay of relaxation,
              adventures, and learning. Monica starts studying Yoga with Adriano
              in 2019; together they open the Lalitā House Yoga Camp. At Home
              she makes you feel like part of the family, during your stay you
              can always count on her because she is able to organize the best,
              most personalized experience for you. She will also delight you
              with incredible, homemade food.
            </p>
            <Button variant="outline-dark" className="mt-3 mb-5">
              <a href="/food" style={{ color: "black" }}>
                FOOD
              </a>
            </Button>
          </Col>
          <Col className="d-flex flex-column justify-content-around  ">
            <img src={adriano} className="img-fluid ml-5" alt="yoga" />

            <h1>Adriano</h1>
            <h5>Founder & Yoga</h5>
            <p style={{ textAlign: "justify" }}>
              Brazilian from Salvador de Bahia, he starts practicing and
              studying Yoga at the age of 17. He arrived in Portugal in 2016 to
              teach the traditional Yoga that he had learned from his teachers.
              Adriano is a Yoga Alliance certified experienced teacher (800h
              RYT) in traditional Yoga and Vedanta with over 2000 hours of
              teaching experience. He will guide you, with kindness and
              dedication, through the original techniques described in the old
              Yoga manuals, following the path of the Vedanta values. During the
              courses he will share the theory of Yoga and the procedure to
              create a safe, authentic Practice.
            </p>
            <Button variant="outline-dark" className="mt-3 mb-5">
              <a
                href="https://www.instagram.com/adrianomesquita_/"
                style={{ color: "black" }}
              >
                INSTAGRAM
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
      <Container     className="d-flex justify-content-center mb-5 ">
        <Row
          xs={1}
          md={3}
          className="d-flex justify-content-around align-items-center mt-5 "
        >
            <Col className="d-flex flex-column justify-content-around">
            <img src={ana} className="img-fluid ml-5" alt="yoga"   />
            <h2>Ana</h2>
            <h5>Yoga</h5>

            <p style={{ textAlign: "justify" }}>
            Ana is a certified Yoga teacher (300h) of Hatha, Yin, and restorative Yoga from Portugal and she found in Ericeira a second home. She shares practices accessible to everybody without exception, that aim to self-listen, self-compassion and forgiveness. She inspires you to feel what you're really feeling and to discover yourself again beneath all the layers, both on the mat and in the everyday life. Her Yoga style comes from the heart and invites you to observe and balance your body, mind, and emotions, and to recognize the harmony and the strength that exist in each of us.
            </p>
                <div className="mb-4"></div>
            <Button variant="outline-dark mt-2" style={{}}>
              <a
                href="https://www.instagram.com/heartwayyoga/"
                style={{ color: "black" }}
              >
                INSTAGRAM
              </a>
            </Button>
            <div className="mt-5"></div>
          </Col>
          <Col className="d-flex flex-column justify-content-around">
            <img src={miguellourenco} className="img-fluid ml-5 " alt="yoga" />
            <h2>Miguel & Lourenço</h2>
            <h5>Surf</h5>
            <p style={{ textAlign: "justify" }}>
              Miguel and Lourenço from the Semente Surf School are our partners
              since the opening of the Lalitā House Yoga Camp. Locals 100%, they
              know the waves of Ericeira for 20 years and have 10 years of
              teaching experience. They are professional, certified coaches who
              will accompany you in the safest, funniest adventure of the
              European Surfing Reserve. Our Yoga routine provides a very
              significant improvement for your surfing skills: the Āsana
              practice confers balance, confidence, and energy, the breathing
              exercises expand the lung capacity and train the mind to deal with
              stressful situations, the meditation increases your focus.
            </p>
            <Button variant="outline-dark" className="mt-4 mb-5">
              <a
                href="https://www.sementesurfschool.pt"
                style={{ color: "black" }}
              >
                WEBSITE
              </a>
            </Button>
          </Col>
          <Col className="d-flex flex-column justify-content-around">
            <img src={lucia} className="img-fluid ml-5" alt="yoga"   />
            <h2>Lucía</h2>
            <h5>Ayurveda</h5>

            <p style={{ textAlign: "justify" }}>
              Lucía is a physician from Buenos Aires, Argentina. She arrived in
              Ericeira in 2021 after her postgraduate studies in Ayurvedic
              Medicine, the traditional medicine of India. Lucía will propose
              you Ayurvedic Yoga massage, a deep tissue oil massage with
              different Yoga stretches to everybody’s needs, it can be relaxing,
              energizing, deep or remedial. During the consultation, you will
              learn how to take care of yourself according to your individual
              requirements. The prevention is better than the cure through
              certain interventions of your lifestyle or daily routine.
            </p>
                <div className="mb-5"></div>
            <Button variant="outline-dark mt-2" style={{}}>
              <a
                href="https://www.luavenatti.com/"
                style={{ color: "black" }}
              >
                WEBSITE
              </a>
            </Button>
            <div className="mt-5"></div>
          </Col>
          
        </Row>
      </Container>
      <AnyQuestions />
      <ContactForm />
      <MainFooter />
    </div>
  );
}

export default OurFamily;
