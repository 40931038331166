import React from "react";
import { Button, Container } from "react-bootstrap";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import Carousel from "react-bootstrap/Carousel";
import MenuBar from "./MenuBar";
import ayuverd1 from "./assets/ayurved1.JPG";
import ayurved3 from "./assets/ayurved3.JPG";
import ayurved4 from "./assets/ayurved4.JPG";
function YogaayurvedaandnatureretreatPage() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <Container fade fluid className="p-0">
        <Carousel fade controls={false} indicators={false}>
          <Carousel.Item interval={2500}>
            <img
              className="d-block w-100"
              src={ayuverd1}
              alt="Yoga, Ayurveda, Nature retreat at the Lalitā House Yoga Camp"
            />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img
              className="d-block w-100"
              src={ayurved3}
              alt="Yoga, Ayurveda, Nature retreat at the Lalitā House Yoga Camp"
            />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img
              className="d-block w-100"
              src={ayurved4}
              alt="Yoga, Ayurveda, Nature retreat at the Lalitā House Yoga Camp "
            />
          </Carousel.Item>
        </Carousel>
      </Container>
      <br></br>
      <h1>Yoga, Ayurveda, Nature's connection retreat </h1>
      <hr></hr>

      <Container>
        <p style={{ textAlign: "justify" }}>
        Lalitā House Yoga Camp proposes for everybody a relaxing Yoga week of knowledge with Ayurveda, massage, Nature’s connection.
        </p>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>
              <b>Available dates</b>
            </h3>
            <ul
              style={{
                textAlign: "justify",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                margin: "auto",
              }}
            >
              <li>
                <b>April 05th–12th</b>
              </li>
              <li>
                <b>June 21st–28th</b>
              </li>
              <li>
                <b>July 12th–19th</b>
              </li>
              <li>
                <b>October 11th–18th</b>
              </li>
            </ul>
          </div>
          <br></br>

          <Button
            variant="dark"
            className="mb-5"
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Check availability
            </a>
          </Button>

          <p style={{ textAlign: "justify" }}>
            A week of discovery awaits you with Yoga practices (dynamic Hatha
            Yoga in the morning and restorative Yin Yoga in the evening)
            Meditations, Prāṇāyāma and Yoganidrā, enriching Ayurveda studies and
            workshop, guided Forest bathing session, relaxing Ayurveda massage.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            This Yoga week will be full of positive energies, healing moments,
            immersions in nature, new learnings, fun, relaxation.
          </p>
          <p style={{ textAlign: "justify" }}>
            The retreat will host a small group of committed participants to
            keep a cozy, familiar environment of our Home.
          </p>

          <p style={{ textAlign: "justify" }}>
            You can expect enriching traditional Hatha Yoga studies, during
            which you will explore the following practices:
            <ul>
              <li>
                <b>Āsana:</b> A balanced series of technical moves, serving as a
                physical preparation to balance your body.
              </li>
              <li>
                <b>Prānāyāma:</b> Breathing exercises that relax the mind and
                energize the body.
              </li>
              <li>
                <b>Dharana and Dhyāna:</b> Concentration and meditation
                techniques to keep the mind focused and centered.
              </li>
              <li>
                <b>Meditation of Mantra Om:</b> Deep sound meditation to
                identify our true nature and being.
              </li>
              <li>
                <b>Yoganidrā:</b> A relaxation session important for balancing
                energies and setting life goals.
              </li>
              <li>
                <b>Ayurveda Massage:</b> A deep tissue warm oil massage with
                assisted yoga stretches to promote healing and relaxation.
              </li>
              <li>
                <b>Nature's Connection Experience:</b> A practice to de-stress
                the body, clear the mind, and boost overall well-being.
              </li>
              <li>
                <b>Trātaka Meditation:</b> A simple practice that balances the
                nervous system and relieves anxiety and insomnia.
              </li>
            </ul>
          </p>

          <p style={{ textAlign: "justify" }}>
            There will be plenty of free time in between the Yoga program to
            hike and enjoy the nearby cliffs and beaches, to explore the
            fishermen town of Ericeira (European World surfing reserve) or the
            historical city of Sintra (World cultural Heritage Site), for
            surfing, bouldering or just relax and benefit from your free time.
          </p>
          <p style={{ textAlign: "justify" }}>
            At the retreat you will be offered proper home-made food: balance,
            healthy, pure. Nutrition is not just about what we eat, it’s also
            about how, where, and who we eat with. We follow the Yogic diet
            called Sattvic with simple vegetarian dishes made with love which
            includes seasonal fresh fruits and vegetables, whole grains,
            legumes, and dairy products. We also propose many delicious vegan
            options.
          </p>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <br></br>

          <h3>Daily Schedule</h3>

          <ul
            style={{
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              margin: "auto",
            }}
          >
            <li>
              <b>8.00 AM</b> Meditation
            </li>
            <li>
              <b>8.30 AM</b> Yoga Āsana and Prāṇāyāma
            </li>
            <li>
              <b>10.00 AM</b> Brunch
            </li>
            <li>
              <b>12.00</b> Nature’s connection Experience (1 per week)
            </li>
            <li>
              <b>Beach time, Surf course (on demand)</b>
            </li>

            <li>
              <b>2.00 PM - 3.00 PM</b> Ayurveda Massage
            </li>

            <li>
              <b>4.00 PM</b> Ayurveda workshop (1 per week)
            </li>
            <li>
              <b>5.00 PM</b> Yoga Āsana and Yoganidrā
            </li>
            <li>
              <b>7.00 PM</b> Dinner
            </li>
            <li>
              <b>8.30 PM</b> Trātaka Meditation (1 per week)
            </li>
          </ul>
        </div>
        <br></br>

        <div>
          <h3>What's included</h3>

          <ul>
            <li> Morning Meditation and Mantra</li>
            <li> Morning dynamic Yoga Āsana practice</li>
            <li> Morning Prāṇāyāma</li>
            <li>
              {" "}
              Experienced yoga instructors, Certified Forest bathing guide,
              Graduated Ayurveda therapist
            </li>
            <li> 1 Nature’s connection Experience</li>
            <li> 1 Ayurveda Massage</li>
            <li> 1 Afternoon Ayurveda workshop</li>
            <li> Afternoon restorative Yoga Āsana practice</li>
            <li> Afternoon relaxing Yoganidrā</li>
            <li> 1 special evening Meditation</li>
            <li> Healthy vegetarian Brunch</li>
            <li> Sattvic Dinner</li>
            <li> 7 nights’ accommodation (shared double or private double)</li>
          </ul>
        </div>

        <div>
          <h3>Participants Skill level</h3>
          <ul>
            <li>Beginner</li>
            <li>Intermediate</li>
            <li>Advanced</li>
          </ul>
        </div>

        <br></br>
      </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default YogaayurvedaandnatureretreatPage;
