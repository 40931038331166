import React from "react";
import { Button,  Container } from "react-bootstrap";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import Carousel from "react-bootstrap/Carousel";
import MenuBar from "./MenuBar";
import retreat1 from "./assets/retreat1.jpg";
import retreat2 from "./assets/retreat2.jpg";
import retreat3 from "./assets/retreat3.jpg";
import retreat4 from "./assets/retreat4.jpg";
function RetreatPage() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <Container fade fluid className="p-0">
        <Carousel fade controls={false} indicators={false}>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={retreat1} alt="Retreat Course" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={retreat2}alt="Retreat Course"/>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={retreat3} alt="Retreat Course"/>
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={retreat4}alt="Retreat Course" />
          </Carousel.Item>
        </Carousel>
      </Container>
      <br></br>
      <h1>Traditional Yoga Retreat</h1>
      <hr></hr>

      <Container>
        <p style={{ textAlign: "justify" }}>
          We invite you in a state of calmness surrounded by the stunning
          Oceanic nature, during this week of discovery and rest shared with
          like-minded people, you will be brought closer to your inner self.
        </p>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>
              <b>Available dates</b>
            </h3>
            <ul
              style={{
                textAlign: "justify",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                margin: "auto",
              }}
            >
              <li>
                <b>April 20th-27th FULLY BOOKED</b>
              </li>
              <li>
                <b>July 13rd-20th FULLY BOOKED</b>
              </li>
              <li>
                <b>September 14th-21st FULLY BOOKED</b>
              </li>

            </ul>
          </div>
          <br></br>

          <Button
            variant="dark"
            className="mb-5"
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Check availability
            </a>
          </Button>


          <p style={{ textAlign: "justify" }}>
            The Traditional Yoga Retreat is based on the classical Ashram
            program and concept of approaching or deepening the knowledge of Yoga;
            this will naturally lead you to introduce the Yogic lifestyle to your
            daily routine.
          </p>
          <p style={{ textAlign: "justify" }}>
            You can expect enriching traditional Hatha Yoga studies, during
            which you will explore the following learnings:
            <ul>
              <li>
                <b>Āsana:</b> a balanced series of technical moves, as a
                physical and spiritual self-consciousness
              </li>
              <li>
                <b>Ṣaṭkarma:</b> traditional Hatha Yoga physiological, organic
                purification exercises
              </li>
              <li>
                <b>Prānāyāma:</b> different types of breathing to relax the mind
                and energize the body
              </li>
              <li>
                <b>Dharana and Dhyāna:</b> concentration and meditation are
                essential for a healthy practice and to focus on your daily life
              </li>
              <li>
                <b>Yoganidrā:</b> relaxation sessions between wakefulness and
                deep sleep, essential to establish energies and set life goals.
              </li>
            </ul>
          </p>
          <p style={{ textAlign: "justify" }}>
            You also will have plenty of free time in between the Yoga program
            to visit the nearby cliffs and beaches, to explore the fishermen
            town of Ericeira (European World surfing reserve) or the historical
            city of Sintra (World cultural Heritage Site), for surfing, hiking,
            bouldering or just relax and enjoy your free time.
          </p>
          <p style={{ textAlign: "justify" }}>
            At the retreat you will be offered proper food: balance, healthy,
            pure. Nutrition is not just about what we eat, it’s also about how,
            where, and who we eat with. We follow the Yogic diet called Sattvic
            with simple vegetarian dishes made with love which includes seasonal
            fresh fruits and vegetables, whole grains, legumes, and dairy
            products. We also propose many delicious vegan options.
          </p>
          <p style={{ textAlign: "justify" }}>
            The retreat hosts a small group to keep the cosy, familiar
            environment of our Home.
          </p>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <br></br>

          <h3>Daily Schedule</h3>

          <ul style={{
  textAlign: "justify",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  margin: "auto",
}}>
  <li>
    <b>7.50 AM</b> Herbal tea and fruits
  </li>
  <li>
    <b>8.00 AM</b> Ṣaṭkarma & Vedic Meditation
  </li>
  <li>
    <b>8.30 AM</b> Yoga Āsana and Prāṇāyāma
  </li>
  <li>
    <b>10.00 AM</b> Brunch
  </li>
  <li>
    <b>Beach time, Surf course (on demand)</b>
  </li>
  <li>
    <b>12.00 – 3 PM</b> Massage session
  </li>
  <li>
    <b>4.00 PM</b> Yoga workshop and lectures (2 per week)
  </li>
  <li>
    <b>5.00 PM</b> Yoga Āsana Clinic and Yoganidrā
  </li>
  <li>
    <b>7.00 PM</b> Dinner
  </li>
  <li>
    <b>8.30 PM</b> Trātaka Meditation (1 per week)
  </li>
</ul>

        </div>
        <br></br>

        <div>
  <h3>What's included</h3>

  <ul>
    <li> Morning Meditation</li>
    <li> Morning dynamic Yoga Āsana practice</li>
    <li> Satkarma workshop and practice</li>
    <li> Prāṇāyāma practices every session (breathwork)</li>
    <li> Experienced Yoga instructors Yoga Alliance certified</li>
    <li> 1 Ayurveda Massage</li>
    <li> 1 Afternoon Yoga workshop</li>
    <li> Afternoon Yoga Āsana Clinic</li>
    <li> Afternoon relaxing Yoganidrā</li>
    <li> 1 special evening Meditation</li>
    <li> Healthy vegetarian Brunch</li>
    <li> Sattvic vegan Dinner</li>
    <li> 7 nights’ accommodation</li>
  </ul>
</div>

        <div>
          <h3>Participants Skill level</h3>
          <ul>
            <li>Beginner</li>
            <li>Intermediate</li>
            <li>Advanced</li>
          </ul>
        </div>

        <br></br>
      </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default RetreatPage;
